import React from 'react'
import MainLayout from '../layouts/MainLayout'
import AlphrView from '../views/AlphrView'

const AlphrPage = () => {
  return (
    <MainLayout className="Alphr">
      <AlphrView />
    </MainLayout>
  )
}

export default AlphrPage
