import React from 'react'
import smImage from '../../assets/images/hero-alphr-sm.png'
import mdImage from '../../assets/images/hero-alphr-md.png'
import lgImage from '../../assets/images/hero-alphr.png'
import CaseStudyView from './CaseStudyView'
import logo from '../../assets/images/brands/alphr-white.png'

const alphrItems = [
  { label: 'case-study:sector', value: 'case-study:alphr:sector' },
  { label: 'case-study:employees', value: '50 +' },
]

const AlphrView = () => {
  return (
    <>
      <CaseStudyView
        caseStudy="alphr"
        title="case-study:alphr:title"
        buttonText="action:see-case-study"
        smImage={smImage}
        mdImage={mdImage}
        lgImage={lgImage}
        logo={logo}
        alt="alphr"
        items={alphrItems}
        percentText="case-study:alphr:percent-text"
        descriptionTitle="our-approach:title"
        descriptionChallenge="case-study:alphr:challenge:description"
        descriptionSolution="case-study:alphr:solution:description"
        descriptionOutcome="case-study:alphr:outcome:description"
      />
    </>
  )
}

export default AlphrView
